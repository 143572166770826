import { MutationTree } from "vuex";

import { DebugData, DebugState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<DebugState> = {
	SET_DEBUG(state, payload: Partial<DebugData> | null) {
		state.debug = payload || {};
	},
};
