import { ContentInterface } from "@scrinz/dtos";

import { client, gql } from "@/graphql";

import { DataService } from "./data.service";

export class ContentService<
	T extends ContentInterface = ContentInterface
> extends DataService<T> {
	constructor(public displayId: string, public contentSlot: string) {
		super();
		this.fetch();
	}

	// async _fetchHttp(): Promise<T | undefined> {
	// 	const res = await http.get(`display/${this.displayId}/${this.contentSlot}`);
	// 	if (!res || res.status !== 200) return;
	// 	return res.data;
	// }

	async _fetch() {
		const query = gql`
			query GetDisplayContent($id: String!, $slot: String!) {
				getDisplayContent(id: $id, slot: $slot) {
					id
					type
					start
					end

					... on ContentBannerInterface {
						duration
					}

					... on ContentBannerAsset {
						asset {
							hashid
							hasCropped
						}
					}

					... on ContentBannerTemplate {
						template
						templateText
					}

					... on ContentNote {
						text
						icon
						iconColor
						sticky
					}
				}
			}
		`;

		const data = await client.request(query, {
			id: this.displayId,
			slot: this.contentSlot,
		});
		this._data = data.getDisplayContent;
		return this._data;
	}
}
