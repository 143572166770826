import { MutationTree } from "vuex";

import { InstallationData, InstallationState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<InstallationState> = {
	SET_INSTALLATION(state, payload: InstallationData | null) {
		state.installation = payload;
	},
};
