import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { DebugState } from "./types";
export * from "./types";

const state: DebugState = {
	debug: {},
};

export const DebugStore: Module<DebugState, RootState> = {
	state,
	getters,
	actions,
	mutations,
};

export default DebugStore;
