import { DisplayOrientationEnum } from "@scrinz/dtos";

export function orientationSizeStyle(orientation: DisplayOrientationEnum) {
	return {
		width: `${
			orientation === DisplayOrientationEnum.Horizontal ? 1920 : 1080
		}px`,
		height: `${
			orientation === DisplayOrientationEnum.Horizontal ? 1080 : 1920
		}px`,
	};
}
