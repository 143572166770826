



































































import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import { SdNoteBoard, SdServiceModules, SdSlider } from "@scrinz/components";
import {
	ContentSlotEnum,
	DisplayLayoutEnum,
	DisplayOrientationEnum,
} from "@scrinz/dtos";

import {
	ContentService,
	DisplayServices,
	ManifestService,
	TransitTimesService,
	WeatherService,
} from "../../services";
import { Getter } from "vuex-class";

@Component({
	components: {
		SdNoteBoard,
		SdServiceModules,
		SdSlider,
	},
})
export default class AbsoluteLayout extends Vue {
	@Getter orientation!: DisplayOrientationEnum;
	@Getter layout!: DisplayLayoutEnum;

	services!: DisplayServices;

	@InjectReactive()
	manifest!: ManifestService;

	@Prop({ default: false })
	demo!: boolean;

	@Prop({ default: false })
	hideModules!: boolean;

	get clockDisabled() {
		// return this.manifest?.modules.clock === false;
		return false;
	}

	get transitTimesDisabled() {
		// return this.manifest?.modules.transit === false;
		return false;
	}

	get weatherDisabled() {
		// return this.manifest?.modules.weather === false;
		return false;
	}

	get styleClasses() {
		return {
			colors: this.demo,
			"hide-modules": this.hideModules,
			horizontal: this.orientation === DisplayOrientationEnum.Horizontal,
			vertical: this.orientation === DisplayOrientationEnum.Vertical,
		};
	}

	created() {
		const man = this.manifest;
		man.addListener(() => {
			this.services = {
				manifest: man,
				weather: new WeatherService(man),
				transit: new TransitTimesService(man),
				content: {
					note: new ContentService(man.displayId, ContentSlotEnum.NOTE),
				},
			};
		});
	}
}
