import { GetterTree } from "vuex";

import { RootState } from "../types";
import { DebugData, DebugState } from "./types";

export const getters: GetterTree<DebugState, RootState> = {
	debug(state): Partial<DebugData> {
		return state.debug;
	},
};
