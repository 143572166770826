











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { SdNoteBoard } from "@scrinz/components";

@Component({
	components: { SdNoteBoard },
})
export default class DebugNotesView extends Vue {
	@Getter displayId!: string;

	@Prop()
	orientation!: number;

	manifest = {
		codeWord: "Dummy",
		displayInformation: "Hubba Bubba",
		organization: {
			name: "Dummy",
		},
	};
}
