

















































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import { RegistrationException, RegistrationExceptionCodes } from "@/store";
import CenteredCardLayout from "@/layouts/CenteredCard.vue";

export enum SetupSteps {
	Setup,
	Processing,
	Success,
	Error,
}

@Component({
	components: { CenteredCardLayout },
})
export default class Setup extends Vue {
	steps = SetupSteps;

	displayId = "";
	apiKey = "";
	orientation: DisplayOrientationEnum | null = null;

	step: SetupSteps = SetupSteps.Setup;
	error: RegistrationException | undefined;
	redirectCountdownCounter = 0;
	redirectCountdownTimeout!: number | undefined;

	getErrorTitle(error?: RegistrationException) {
		if (!error) return "Unknown Error";
		return error.code === RegistrationExceptionCodes.HttpError
			? `${error.response.statusCode} - ${error.response.error}`
			: error.code === RegistrationExceptionCodes.NetworkError
			? `No API connection`
			: error.message;
	}

	getErrorMessage(error?: RegistrationException) {
		switch (error?.code) {
			case RegistrationExceptionCodes.DisplayNotFound:
				return "The provided display ID couldn't be found.";
			case RegistrationExceptionCodes.InvalidApiKey:
				return "The API key provided didn't match with given display ID. Ensure you've entered both correctly.";
			case RegistrationExceptionCodes.HttpError:
				return "An unexpected network error occured. Contact support and supply following information:";
			case RegistrationExceptionCodes.NetworkError:
				return "Couldn't make contact with the registration API. The server might be down, or your local network might not be connected to the Internet.";
			default:
				return "An unknown error occured. Contact support and supply following information to get a resolution.";
		}
	}

	showErrorResponse(error?: RegistrationException) {
		if (!error) return false;
		return (
			error.response &&
			[
				RegistrationExceptionCodes.DisplayNotFound,
				RegistrationExceptionCodes.InvalidApiKey,
				RegistrationExceptionCodes.HttpError,
				RegistrationExceptionCodes.NetworkError,
			].includes(error.code)
		);
	}

	showErrorStackTrace(error?: RegistrationException) {
		if (!error) return false;
		return (
			(error.stack || (error.error && error.error.stack)) &&
			[
				RegistrationExceptionCodes.NetworkError,
				RegistrationExceptionCodes.HttpError,
				RegistrationExceptionCodes.UnknownError,
			].includes(error.code)
		);
	}

	async register(event: Event) {
		event.preventDefault();

		this.step = SetupSteps.Processing;
		this.error = undefined;

		const requestData = {
			apiKey: this.apiKey,
			orientation: this.orientation,
			displayId: this.displayId,
		};

		try {
			const installation = await this.$store.dispatch(
				"registerDisplay",
				requestData,
			);

			if (!installation) {
				throw new Error("Failed to register display.");
			}

			this.redirectCountdown();
			this.step = SetupSteps.Success;
		} catch (err) {
			this.error = err as RegistrationException;

			// tslint:disable:no-magic-numbers ter-newline-after-var
			const countdownTimes: { [code: number]: number } = {};
			countdownTimes[RegistrationExceptionCodes.HttpError] = 30;
			countdownTimes[RegistrationExceptionCodes.UnknownError] = 30;
			// tslint:enable:no-magic-numbers ter-newline-after-var

			this.redirectCountdown(false, countdownTimes[err.code] || 5);
			this.step = SetupSteps.Error;
		}
	}

	goBackToSetup(event?: Event) {
		if (event) event.preventDefault();

		if (this.redirectCountdownTimeout) {
			clearTimeout(this.redirectCountdownTimeout);
		}

		this.redirectCountdownCounter = 0;
		this.step = SetupSteps.Setup;
	}

	redirectCountdown(success = true, initialSeconds = 3) {
		if (this.redirectCountdownCounter === 0) {
			this.redirectCountdownCounter = initialSeconds;
		} else {
			this.redirectCountdownCounter -= 1;
		}

		if (this.redirectCountdownCounter === 0) {
			if (success) this.$router.push("/");
			else this.goBackToSetup();

			return;
		}

		this.redirectCountdownTimeout = window.setTimeout(
			() => {
				this.redirectCountdown(success);
			},
			1000, // tslint:disable-line:no-magic-numbers
		);
	}
}
