import {
	SdTransitTimesServiceInterface,
	SdTransitTimesStopInterface,
} from "@scrinz/components";

import http from "@/http";
import { AutoRefreshDataService } from "./auto-refresh-data.service";

export class TransitTimesService
	extends AutoRefreshDataService<SdTransitTimesStopInterface[]>
	implements SdTransitTimesServiceInterface
{
	// One minute
	get refreshInterval() {
		return 1000 * 60 * 1;
	}

	constructor(...args: any[]) {
		super(...args);
		console.log(args, this);
	}

	async _fetch() {
		const stops = this._manifest?.transitStops;
		if (!stops || stops.length <= 0) return;

		const ids = stops.map((s) => s.stopId) || [];
		const qs = ids.join("&stop[]=");
		const res = await http.get(`transit/departures/?stop=${qs}`);

		if (!res || res.status !== 200 || !res.data || !(res.data instanceof Array))
			return;

		return res.data.map<SdTransitTimesStopInterface>((departure) => ({
			...departure,
			label: stops.find((s) => s.stopId === departure.stopId)?.label,
		}));
	}
}
