import { GraphQLClient, gql } from "graphql-request";
export { gql };

import { API_PATH } from "./constants";

export const client = new GraphQLClient(`${API_PATH}/graphql`, {
	cache: "no-cache",
});

export default client;
