import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { ManifestState } from "./types";
export * from "./types";

const state: ManifestState = {
	manifest: null,
	orientation: null,
	layout: null,
	overrides: {},
};

export const ManifestStore: Module<ManifestState, RootState> = {
	state,
	getters,
	actions,
	mutations,
};

export default ManifestStore;
