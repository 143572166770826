import { DataService } from "./data.service";
import { ManifestService } from "./manifest.service";

export abstract class AutoRefreshDataService<T> extends DataService<T> {
	abstract refreshInterval: number;
	protected _refreshTimeout?: number;
	protected _refreshStarted = false;

	constructor(manifest?: ManifestService, autoStart = true) {
		super(manifest, false);
		if (autoStart) this.start();
	}

	/**
	 * Starts the service, continuing to update automatically thereafter.
	 */
	start() {
		if (this._refreshStarted) return;
		this._refreshStarted = true;
		this._update();
	}

	/**
	 * Updates the service with new data.
	 */
	protected _update = async () => {
		if (!this._refreshStarted) return;

		// Clear any timeout, to avoid spawning multiple.
		clearTimeout(this._refreshTimeout);

		// Fetch data.
		this.fetch();

		// Set a timeout if an interval exists.
		if (this.refreshInterval) {
			this._refreshTimeout = window.setTimeout(
				this._update,
				this.refreshInterval,
			);
		}
	};
}
