import { GetterTree } from "vuex";

import { DisplayLayoutEnum, DisplayOrientationEnum } from "@scrinz/dtos";
import { ManifestService } from "@/services";
import { detectOrientation } from "@/utils";
import { RootState } from "../types";
import { ManifestState } from "./types";

export const getters: GetterTree<ManifestState, RootState> = {
	manifest(state): ManifestService | null {
		return state.manifest || null;
	},

	orientation(state): DisplayOrientationEnum {
		return (
			state.orientation ?? state.manifest?.orientation ?? detectOrientation()
		);
	},

	layout(state): DisplayLayoutEnum {
		const env = process.env.VUE_APP_DISPLAY_LAYOUT as DisplayLayoutEnum;
		return (
			state.layout ??
			((env in DisplayLayoutEnum && env) || null) ??
			DisplayLayoutEnum.Default
		);
	},
};
