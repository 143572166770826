import { ActionTree } from "vuex";

import { DisplayLayoutEnum, DisplayOrientationEnum } from "@scrinz/dtos";

import { RootState } from "../types";
import { ManifestState } from "./types";
import { ManifestService } from "@/services";
import getenv from "getenv.ts";

export const actions: ActionTree<ManifestState, RootState> = {
	async init({ commit }) {
		commit(
			"SET_LAYOUT",
			getenv.string("VUE_APP_DISPLAY_LAYOUT", DisplayLayoutEnum.Default),
		);
	},

	async initiateManifest(
		{ commit, state },
		payload: {
			displayId: string;
		},
	): Promise<ManifestService> {
		// Return if state is already set, else new promise.
		if (state.manifest) return state.manifest;
		return new Promise((resolve) => {
			// Create a new instance.
			const manifest = new ManifestService(
				payload.displayId,
				state.orientation,
			);

			// Setup a callback function to handle manifest load.
			const callback = () => {
				if (!manifest) return;
				manifest.removeListener(callback);
				commit("SET_MANIFEST", manifest);
				resolve(manifest);
			};

			// Add a listener, and start fetching the manifest.
			manifest.addListener(callback);
			manifest.fetch();
		});
	},

	async setOrientation({ commit }, orientation: DisplayOrientationEnum) {
		commit("SET_ORIENTATION_OVERRIDE", orientation);
	},

	async setLayout({ commit }, layout: DisplayLayoutEnum) {
		commit("SET_LAYOUT", layout);
	},
};
