import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// tslint:disable:object-literal-key-quotes max-line-length quotemark
const messages: VueI18n.LocaleMessages = {};

export default new VueI18n({
	messages,
	locale: "no",
	fallbackLocale: "en",
});
