import { SdWeatherData, SdWeatherServiceInterface } from "@scrinz/components";

import http from "@/http";

import { AutoRefreshDataService } from "./auto-refresh-data.service";

export class WeatherService<T extends SdWeatherData = SdWeatherData>
	extends AutoRefreshDataService<T>
	implements SdWeatherServiceInterface
{
	// 15 minutes
	get refreshInterval() {
		return 1000 * 60 * 15;
	}

	async _fetch(city = this._manifest?.city): Promise<T | undefined> {
		const res = await http.get<T>(`weather/weather`, {
			params: { city },
		});
		if (!res || res.status !== 200) return;
		return res.data;
	}
}
