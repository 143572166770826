import debug from "debug";
import { captureException } from "@sentry/minimal";

import { SdComponentServiceInterface } from "@scrinz/components";
import { ManifestService } from "./manifest.service";

export abstract class DataService<
	T,
	CB extends (data: T) => void = (data: T) => void
> implements SdComponentServiceInterface<T, CB> {
	/**
	 * Construct a data service, listening to `manifest`.
	 * @param manifest The manifest service to listen to.
	 */
	constructor(manifest?: ManifestService, fetch = false) {
		if (manifest) this.setManifest(manifest);
		if (fetch) this.fetch();
	}

	/**
	 * The fetched data, or undefined.
	 */
	get data() {
		return this._data;
	}
	protected _data?: T;

	/**
	 * An optional manifest details.
	 */
	protected _manifest?: ManifestService;

	/**
	 * Whether the service should automatically update when manifest changes?
	 */
	protected _updateOnManifestChange = true;

	/**
	 * Array of callback listener, to be updated on changes to data.
	 */
	protected _listeners: CB[] = [];

	/**
	 * Internal abstract method to implement the actual fetching from server or
	 * other data source.
	 */
	protected abstract _fetch(): Promise<T | undefined>;

	/**
	 * Fetches the data from the service.
	 */
	async fetch(): Promise<T | undefined> {
		// Update and notify if able to get data.
		try {
			this._debug("fetching data");
			const data = await this._fetch();
			if (data) {
				this._debug("got data", data);
				this._data = data;
				this.notifyListeners();
			}
		} catch (error) {
			this._debug("failed to fetch", this.constructor.name, error);
			captureException(error);
		}

		return this._data;
	}

	/**
	 * Adds a listener to notify about updates.
	 *
	 * @param callback The callback function to call.
	 */
	addListener(callback: CB) {
		if (typeof callback !== "function")
			throw TypeError("callback must be funciton");
		this.removeListener(callback);
		this._listeners.push(callback);
		if (this._data) callback(this._data);
	}

	/**
	 * Removes a callback from getting updates.
	 *
	 * @param callback The callback to remove.
	 */
	removeListener(callback: CB) {
		const index = this._listeners.findIndex((l) => l === callback);
		if (index !== -1) this._listeners.splice(index, 1);
	}

	setManifest(manifest?: ManifestService) {
		// if (this._manifest) this._manifest.removeListener(this.onManifestChange);
		this._manifest = manifest;
		// if (manifest) manifest.addListener(this.onManifestChange);
	}

	// onManifestChange(manifest: DisplayManifestObject) : void { /** noop */ }

	/**
	 * Resets the service to it's initial state.
	 */
	reset() {
		this._data = undefined;
		this._listeners = [];
	}

	/**
	 * Notifies listeners about updated data.
	 */
	protected notifyListeners() {
		if (!this._data) return;
		this._listeners.forEach((fn) => fn(this._data as T));
	}

	/** A method for easy access to namespaced `debug`. */
	protected get _debug() {
		return debug(this.constructor.name);
	}
}
