
















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { DisplayLayoutEnum, DisplayOrientationEnum } from "@scrinz/dtos";

import reload from "../reload";

@Component
export default class DevTools extends Vue {
	layouts = DisplayLayoutEnum;
	orientations = DisplayOrientationEnum;

	@Getter displayId!: string | null;
	@Getter apiKey!: string | null;
	@Getter("orientation") storeOrientation!: DisplayOrientationEnum;
	@Getter("layout") storeLayout!: DisplayLayoutEnum;

	// orientation: DisplayOrientationEnum | null = null;
	showDevTools = false;
	showDevToolsKeyCombo: number[] = [];
	uninstallVerified = false;

	get orientation(): DisplayOrientationEnum {
		return this.storeOrientation;
	}

	set orientation(orientation: DisplayOrientationEnum) {
		void this.$store.dispatch("setOrientation", orientation);
		this.$forceUpdate();
	}

	get layout(): DisplayLayoutEnum {
		return this.storeLayout;
	}

	set layout(DisplayLayoutEnum: DisplayLayoutEnum) {
		void this.$store.dispatch("setLayout", DisplayLayoutEnum);
		this.$forceUpdate();
	}

	async created() {
		document.addEventListener("keydown", e => {
			this.showDevToolsKeyCombo.push(e.keyCode);

			if (this.showDevToolsKeyCombo.length > 4) {
				this.showDevToolsKeyCombo.splice(0, this.showDevToolsKeyCombo.length - 4);
			}

			if (this.showDevToolsKeyCombo.join(",") === "37,37,39,37") {
				this.showDevTools = !this.showDevTools;
			}
		});
	}

	async reload() {
		await reload.reload();
	}

	async uninstall() {
		if (!this.uninstallVerified) {
			this.uninstallVerified = true;

			return;
		}

		await this.$store.dispatch("unsetInstallation");
		this.$router.push("/setup");
	}
}
