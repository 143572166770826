import { ManifestService } from "@/services";
import { DisplayLayoutEnum, DisplayOrientationEnum } from "@scrinz/dtos";
import { MutationTree } from "vuex";

import { ManifestState, ManifestStateOverride } from "./types";

export const mutations: MutationTree<ManifestState> = {
	SET_MANIFEST(state, payload: ManifestService) {
		state.manifest = payload;
	},

	SET_ORIENTATION_OVERRIDE(state, payload: DisplayOrientationEnum | null) {
		state.orientation = payload;
	},

	SET_LAYOUT(state, payload: DisplayLayoutEnum | null) {
		console.log("setting layout", payload);
		state.layout = payload;
	},

	SET_OVERRIDE(state, payload: ManifestStateOverride | null) {
		if (!payload) return;
		Object.keys(payload).forEach(
			(key: any) => ((state as any).overrides[key] = (payload as any)[key]),
		);
	},
};
