import moment from "moment";

import {
	DisplayEntityObject,
	DisplayManifestObject,
	DisplayOrientationEnum,
} from "@scrinz/dtos";

import { client, gql } from "@/graphql";
import getenv from "getenv.ts";

import { API_PATH } from "@/constants";
import { DataService } from "./data.service";
import { detectOrientation } from "@/utils";

export class ManifestService
	extends DataService<DisplayManifestObject>
	implements Readonly<DisplayManifestObject> {
	constructor(
		public readonly displayId: string,
		public orientationOverride?: DisplayOrientationEnum | null,
	) {
		super();
	}

	// // listener: (m: DisplayManifestObject) => void;
	// private _services: Array<{ service: DataService<any> }> = [];
	// registerService(services: DataService<any> | DataService<any>[]) {
	// 	if (!(services instanceof Array)) services = [services];
	// 	services.forEach((service) => {
	// 		service.setManifest(this);
	// 		this._services.push({ service });
	// 	});
	// }

	// async _fetchHttp() {
	// 	const res = await http.get<DisplayManifestObject>(
	// 		`display/${this.displayId}/manifest`,
	// 	);
	// 	if (!res || res.status !== 200 || !res.data) return;
	// 	this._data = res.data;
	// 	if (this._data.timeZone) moment.tz.setDefault(this._data.timeZone);
	// 	return this._data;
	// }

	async _fetch() {
		const query = gql`
			query GetDisplayManifest($displayId: String!) {
				getDisplayManifest(id: $displayId) {
					codeWord
					showCodeWord
					timeZone
					displayId
					displayInformation
					layout
					orientation

					display {
						noteBoardBackgroundColor
					}

					organization {
						noteBoardBackgroundColor
					}

					modules {
						name
					}

					transitStops {
						label
						stopId
					}

					fallback {
						key
						content {
							start
							end
							slot
							type
						}
					}
				}
			}
		`;

		const data = await client.request(query, { displayId: this.displayId });
		this._data = data.getDisplayManifest;
		if (this._data?.timeZone) moment.tz.setDefault(this._data.timeZone);
		if (this._data?.orientation)
			this._data.orientation = (DisplayOrientationEnum as any)[
				this._data.orientation
			];
		return this._data;
	}

	get assetsBasePath() {
		return `${API_PATH}/assets/`;
	}

	get bannerFallbackText() {
		return (
			// this._data?.entity.bannerFallbackText ||
			// this._data?.organization?.bannerFallbackText
			"fallback"
		);
	}

	get bannerTemplateVariables() {
		const smsPhoneNumber = getenv.string("VUE_APP_SMS_NUMBER");

		return {
			sms: {
				codeWord: this._data?.codeWord,
				number: smsPhoneNumber,
				numberNoSpace: smsPhoneNumber.replace(/\s/g, ""),
			},

			organization: {
				name: this._data?.organization?.name,
				codeWord: this._data?.organization?.codeWord,
				street: this._data?.organization?.street,
				city: this._data?.organization?.city,
				zip: this._data?.organization?.zip,
			},

			entity: {
				name: this._data?.display.name,
				street: this._data?.display.street,
				city: this._data?.display.city,
				zip: this._data?.display.zip,
			},
		};
	}

	get city() {
		return this._data?.display.city || this._data?.organization?.city;
	}

	get display() {
		return (this._data?.display as unknown) as DisplayEntityObject;
	}

	get fallback() {
		return this._data?.fallback || [];
	}

	get modules() {
		return this._data?.modules || [];
	}

	get organization() {
		return this._data?.organization;
	}

	get orientation() {
		if (this.orientationOverride) return this.orientationOverride;
		return (
			this._data?.orientation ||
			detectOrientation() ||
			DisplayOrientationEnum.Horizontal
		);
	}

	get transitStops() {
		return this._data?.transitStops || [];
	}
}
