


































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class DebugMainView extends Vue {
	debugInfo = {
		"window.devicePixelRatio": window.devicePixelRatio,
	};
}
