import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue from "vue";

const dsn =
	"https://18c2b69d6a94407fbc3a1d23d99b08ff@o224998.ingest.sentry.io/5519690";

Sentry.init({
	Vue,
	dsn,
	enabled: process.env.NODE_ENV === "production",
	logErrors: process.env.NODE_ENV === "development" && true,
	debug: false && process.env.NODE_ENV === "development",
	environment: process.env.NODE_ENV,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.1,
});
