import { ActionTree } from "vuex";
import localforage from "localforage";

import { RootState } from "../types";
import { DebugData, DebugState } from "./types";

const DEBUG_DATA_KEY = "DEBUG_DATA";

export const actions: ActionTree<DebugState, RootState> = {
	async init({ commit }) {
		const data = await localforage.getItem<any>(DEBUG_DATA_KEY);

		if (!data || typeof data !== "object") return;

		commit("SET_DEBUG", data);
	},

	async setDebugData({ commit, state }, data: DebugData) {
		const debug = {
			...(state.debug || {}),
			...data,
		};

		await localforage.setItem(DEBUG_DATA_KEY, debug);
		commit("SET_DEBUG", debug);

		return debug;
	},
};
