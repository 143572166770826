



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import DisplayRenderer from "@/components/DisplayRenderer.vue";
import PreviewOptions from "@/components/PreviewOptions.vue";
import PreviewScroller from "@/components/PreviewScroller.vue";

@Component({
	components: { DisplayRenderer, PreviewOptions, PreviewScroller },
})
export default class Display extends Vue {
	@Prop({ required: true })
	displayId!: string;

	previewOrientation: DisplayOrientationEnum | null = null;
	scalePercent = 70; // tslint:disable-line:no-magic-numbers

	get scale() {
		return this.scalePercent / 100;
	}

	get wrapperStyle() {
		return {
			transform: `translate(-50%, -50%) scale(${this.scale})`,
		};
	}
}
