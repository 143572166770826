













































import { Component, InjectReactive, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { SdNoteBoard, SdServiceModules, SdSlider } from "@scrinz/components";
import { ContentSlotEnum, DisplayOrientationEnum } from "@scrinz/dtos";

import {
	ContentService,
	DisplayServices,
	ManifestService,
	TransitTimesService,
	WeatherService,
} from "../../services";

@Component({
	components: {
		SdNoteBoard,
		SdServiceModules,
		SdSlider,
	},
})
export default class AbsoluteLayout extends Vue {
	@Getter orientation!: DisplayOrientationEnum;

	@InjectReactive()
	manifest!: ManifestService;

	services!: DisplayServices;

	get styleClasses() {
		return {
			horizontal: this.orientation === DisplayOrientationEnum.Horizontal,
			vertical: this.orientation === DisplayOrientationEnum.Vertical,
		};
	}

	created() {
		const man = this.manifest;
		man.addListener(() => {
			this.services = {
				manifest: man,
				weather: new WeatherService(man),
				transit: new TransitTimesService(man),
				content: {
					note: new ContentService(man.displayId, ContentSlotEnum.NOTE),
					banner: new ContentService(man.displayId, ContentSlotEnum.BANNER),
					partnerLogo: new ContentService(man.displayId, "partnerLogo"),
				},
			};
		});
	}
}
