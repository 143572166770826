import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { InstallationState } from "./types";
export * from "./types";

const state: InstallationState = {
	installation: null,
};

export const InstallationStore: Module<InstallationState, RootState> = {
	state,
	getters,
	actions,
	mutations,
};

export default InstallationStore;
