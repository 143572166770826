






















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PreviewOptions extends Vue {
	@Prop({ default: null, type: [Number] })
	orientationOverride!: number | null;

	@Prop({ default: 100, type: [Number, String] })
	scalePercentage!: number | string | null;

	get scale() {
		return this.scalePercentage as number;
	}

	set scale(value: number) {
		this.$emit("update:scalePercentage", value);
	}

	get orientation() {
		return this.orientationOverride;
	}

	set orientation(value: number | null) {
		this.$emit("update:orientationOverride", value);
	}

	@Watch("orientationOverride", { immediate: true })
	onOrientationOverrideChange() {
		this.orientation = this.orientationOverride;
	}

	@Watch("scalePercentage", { immediate: true })
	onScalePercentageChange() {
		let value = (typeof this.scalePercentage === "string") ? parseInt(this.scalePercentage, 10) : this.scalePercentage;

		if (!value || isNaN(value)) {
			value = 100;
		}

		this.scale = value;
	}
}
