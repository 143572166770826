






























import {
	Component,
	Prop,
	Watch,
	Vue,
	ProvideReactive,
} from "vue-property-decorator";
import { Getter } from "vuex-class";
import DetectNetwork from "v-offline";

import { DisplayLayoutEnum, DisplayOrientationEnum } from "@scrinz/dtos";

import { ManifestService } from "../services";

import CenteredCardLayout from "../layouts/CenteredCard.vue";
import AbsoluteLayout from "../layouts/display/AbsoluteLayout.vue";
import VarigLayout from "../layouts/display/VarigLayout.vue";

export enum DisplayRendererState {
	Loading,
	Offline,
	Loaded,
	Error,
}

@Component({
	components: {
		CenteredCardLayout,
		DetectNetwork,
	},
})
export default class DisplayRenderer extends Vue {
	@Getter orientation!: DisplayOrientationEnum;
	@Getter layout!: DisplayLayoutEnum;

	@Prop({ required: true })
	displayId!: string;

	@ProvideReactive()
	manifest?: ManifestService;

	get layoutComponent() {
		const comps = {
			[DisplayLayoutEnum.Default]: AbsoluteLayout,
			[DisplayLayoutEnum.Varig]: VarigLayout,
		};

		return comps[this.layout];
	}

	get isLoaded() {
		return this.state === DisplayRendererState.Loaded;
	}

	get displayRendererClasses() {
		return {
			horizontal: this.orientation === DisplayOrientationEnum.Horizontal,
			vertical: this.orientation === DisplayOrientationEnum.Vertical,
		};
	}

	states = DisplayRendererState;
	state: DisplayRendererState = DisplayRendererState.Loading;

	isOnline: boolean = navigator.onLine || false;
	loadingDots = "...";
	loadingDotsTimeout?: number;
	errorRetryCountdown = 10;
	errorRetryCountdownTimeout?: number;

	@Watch("displayId", { immediate: true })
	async setupData() {
		this.state = DisplayRendererState.Loading;
		this.clearErrorRetryCountdown();
		this.tickLoadingDots();

		if (!this.isOnline) {
			this.state = DisplayRendererState.Offline;
			return;
		}

		try {
			this.manifest = await this.$store.dispatch("initiateManifest", {
				displayId: this.displayId,
			});

			if (!(this.manifest instanceof ManifestService))
				throw Error("failed to initate manifest");

			this.state = DisplayRendererState.Loaded;
			this.$forceUpdate();
		} catch (err) {
			this.state = DisplayRendererState.Error;
			this.tickErrorRetryCountdown();
		}
	}

	async onNetworkConditionDetected(online: boolean) {
		this.isOnline = online;

		if (online) {
			await this.setupData();
		}
	}

	tickLoadingDots() {
		if (this.loadingDotsTimeout) {
			clearTimeout(this.loadingDotsTimeout);
		}

		this.loadingDotsTimeout = window.setTimeout(() => {
			this.loadingDots =
				this.loadingDots.length === 3 ? "" : `${this.loadingDots}.`;
			this.tickLoadingDots();
		}, 1000); // tslint:disable-line
	}

	clearErrorRetryCountdown() {
		if (this.errorRetryCountdownTimeout) {
			clearTimeout(this.errorRetryCountdownTimeout);
		}
	}

	tickErrorRetryCountdown() {
		this.clearErrorRetryCountdown();

		if (this.errorRetryCountdown === 0) {
			this.state = DisplayRendererState.Loading;
			this.errorRetryCountdown = 30;
			void this.setupData();
		} else {
			this.errorRetryCountdownTimeout = window.setTimeout(() => {
				this.errorRetryCountdown -= 1;
				this.tickErrorRetryCountdown();
			}, 1000);
		}
	}
}
